<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="批次名"
        :tool-list="['campus','keyword']"
        :queryInfo.sync="queryInfo"
        @on-search="renderTable(1)">
        <el-form-item label-width="0" style="margin-left: -20px;">
          <tool-tips-group tips-content="校区数据只能用于<b>搜索条</b>中【宿舍楼】数据的获取。<br/>不能用于过滤表格数据。" />
        </el-form-item>
        <el-form-item label="宿舍楼" prop="dormitoryId" label-width="80px">
          <el-select
            size="small"
            v-model="queryInfo.dormitoryId"
            @change="changeBuild"
            clearable
            filterable>
            <el-option
              v-for="{dormitoryName,id} in dormBuildList"
              :label="dormitoryName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="所在楼层" prop="floorNum">
          <el-select v-model="queryInfo.floorNum" size="small">
            <el-option
              v-for="num in floorList"
              :label="`第${num}层`"
              :value="num"
              :key="num"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="状态" label-width="80px">
          <el-select
            size="small"
            v-model="queryInfo.status"
            clearable
            filterable>
            <el-option
              v-for="{paramValue, paramName} in chargingStatusList"
              :label="paramName"
              :value="Number(paramValue)"
              :key="paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            placeholder=""
            v-model="queryInfo.startDay"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <el-form-item label="结束时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            v-model="queryInfo.endDay"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column label="宿舍" min-width="220">
        <template v-slot="{row}">
          {{ row.dormitoryName }}第{{ row.floorNum }}层{{ row.roomName }}
        </template>
      </el-table-column>
      <el-table-column prop="originalBalance" label="原始度数" min-width="120" align="center" />
      <el-table-column prop="afterBalance" label="设置后度数" min-width="120" align="center" />
      <el-table-column prop="batchName" label="批次名" min-width="160" align="center" />
      <el-table-column prop="afterBalance" label="设置日期" min-width="160" align="center">
        <template v-slot="{row}">
          {{ row.createTime|formatDate('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="220" align="center">
        <template v-slot="{row}">
          <p v-if="row.status||row.status===0">{{ $http.getParamName(row.status, chargingStatusList) }}<span v-if="row.taskStatus">{{ $http.getParamName(row.status, chargingStatusList) !== $http.getParamName(row.taskStatus, taskStatusList) ? '(' + $http.getParamName(row.taskStatus, taskStatusList) + ')' : '' }}</span></p>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageDormFromClearMeterApi } from '@/api/dorm/set-electric-meter-api'

export default {
  name: 'electricMeterClearRecord',
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: null,
        dormitoryId: null,
        floorNum: null,
        startDate: null,
        endDate: null,
        status: null
      },
      chargingStatusList: [], // 赠送电费到账状态（table展示数据用）
      taskStatusList: [], // 赠送电费到账状态（queryInfo过滤数据用）
      dormBuildList: [],
      floorList: 0
    }
  },
  watch: {
    'queryInfo.campusId': function (newVal) {
      if (newVal) {
        this.getDormBuildList({ campusId: newVal })
      } else {
        this.clearList(this.dormBuildList)
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.$http.getParamListByKey('chargingStatus', this, 'chargingStatusList'),
      this.$http.getParamListByKey('taskStatus', this, 'taskStatusList')
    ])
  },
  methods: {
// 获取清空记录列表
    async renderTable (pageNum) {
      await this.getTableData(pageDormFromClearMeterApi, pageNum)
    },
    // 获取宿舍楼
    async getDormBuildList (query = {}) {
      this.floorList = 0
      try {
        const res = await this.$http.getDormBuildQuery(query)
        this.dormBuildList = res.data
      } catch (e) {
      }
    },
    // 选择楼栋
    changeBuild (buildId) {
      this.floorList = 0
      this.queryInfo.floorNum = null
      if (buildId) {
        // 生成楼层
        this.floorList = this.dormBuildList.filter(({ id }) => id === buildId)[0].floorAmount
      }
    }
  }
}
</script>
